import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { IconName } from '@leon-hub/icons';
import RouteName from '@leon-hub/routing-config-names';
import { VCheckbox } from 'web/src/components/Checkbox';
import VHintBlock from 'web/src/components/HintBlock/VHintBlock/VHintBlock.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import VCmsContent from 'web/src/modules/cms/components/VCmsContent/VCmsContent.vue';
import { ButtonHeight, ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useCmsStore } from 'web/src/modules/cms/store';
import { useAffiliateStore } from 'web/src/modules/affiliates/store';
export default /*@__PURE__*/ _defineComponent({
    __name: 'AffiliateActivation',
    setup (__props) {
        const cmsStore = useCmsStore();
        const affiliateStore = useAffiliateStore();
        const router = useRouter();
        const isConsent = ref(false);
        const activationState = ref(false);
        onMounted(()=>cmsStore.fetchCmsContentTranslation({
                key: 'WEB2_AFFILIATE_ACTIVATION_SHORT'
            }));
        function goToAffiliateProgram() {
            router.push({
                name: RouteName.AFFILIATE_PROGRAM
            });
        }
        function onChangeConsent(event) {
            const { checked } = event.target;
            isConsent.value = checked;
        }
        async function onClick() {
            activationState.value = true;
            await affiliateStore.doActivation({
                termsAccepted: true
            });
            activationState.value = false;
        }
        return (_ctx, _cache)=>{
            const _component_router_link = _resolveComponent("router-link");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['affiliate-activation'])
            }, [
                _createVNode(VHintBlock, {
                    class: _normalizeClass([
                        "affiliate-activation__short",
                        _ctx.$style['affiliate-activation__short']
                    ]),
                    icon: _unref(IconName).PEOPLE
                }, {
                    footer: _withCtx(()=>[
                            _createVNode(VButton, {
                                kind: _unref(ButtonKind).SECONDARY,
                                rounded: "",
                                height: _unref(ButtonHeight).LARGE,
                                "full-width": "",
                                onClick: goToAffiliateProgram
                            }, {
                                default: _withCtx(()=>[
                                        _createTextVNode(_toDisplayString(_ctx.$t('JSP_NEWS_MORE')), 1)
                                    ]),
                                _: 1
                            }, 8, [
                                "kind",
                                "height"
                            ])
                        ]),
                    default: _withCtx(()=>[
                            _createVNode(VCmsContent, {
                                "no-padding": "",
                                "cms-key": "WEB2_AFFILIATE_ACTIVATION_SHORT"
                            })
                        ]),
                    _: 1
                }, 8, [
                    "class",
                    "icon"
                ]),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['affiliate-activation__bottom'])
                }, [
                    _createVNode(_unref(VCheckbox), {
                        id: "affiliate-activation-checkbox",
                        class: _normalizeClass(_ctx.$style['affiliate-activation__bottom__checkbox']),
                        name: "consent",
                        checked: isConsent.value,
                        onChange: onChangeConsent
                    }, {
                        label: _withCtx(()=>[
                                _createElementVNode("span", null, [
                                    _createTextVNode(_toDisplayString(_ctx.$t('WEB2_AFFILIATE_CONSENT', {
                                        consentLink: ''
                                    })) + " ", 1),
                                    _createVNode(_component_router_link, {
                                        to: _ctx.$routeName.AFFILIATE_PROGRAM_RULES
                                    }, {
                                        default: _withCtx(()=>[
                                                _createTextVNode(_toDisplayString(_ctx.$t('WEB2_AFFILIATE_CONSENT_LINK')), 1)
                                            ]),
                                        _: 1
                                    }, 8, [
                                        "to"
                                    ])
                                ])
                            ]),
                        _: 1
                    }, 8, [
                        "class",
                        "checked"
                    ]),
                    _createVNode(VButton, {
                        kind: _unref(ButtonKind).PRIMARY,
                        "full-width": "",
                        "is-loading": activationState.value,
                        class: _normalizeClass(_ctx.$style['affiliate-activation__bottom__activation']),
                        disabled: !isConsent.value,
                        label: _ctx.$t('WEB2_ACTIVATE'),
                        onClick: onClick
                    }, null, 8, [
                        "kind",
                        "is-loading",
                        "class",
                        "disabled",
                        "label"
                    ])
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'AffiliateActivation'
                ]
            ]);
        };
    }
});
