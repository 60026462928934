import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import VInformer from 'web/src/components/Informer/VInformer/VInformer.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'AffiliateStats',
    props: {
        balance: {
            default: 0
        },
        profit: {
            default: 0
        },
        profitRate: {
            default: 0
        },
        players: {
            default: 0
        },
        affiliateRegistrationsPage: {
            default: ''
        }
    },
    setup (__props) {
        const props = __props;
        const router = useRouter();
        const profitRateFormatted = computed(()=>{
            if (props.profitRate) return `${props.profitRate}%`;
            return '0%';
        });
        function onRegClick() {
            router.push(props.affiliateRegistrationsPage);
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['affiliate-stats'])
            }, [
                _createVNode(VInformer, null, {
                    label: _withCtx(()=>[
                            _createElementVNode("span", {
                                class: _normalizeClass(_ctx.$style['affiliate-stats__label'])
                            }, _toDisplayString(_ctx.$t('JSPACC_ACC_BALANCE')), 3)
                        ]),
                    value: _withCtx(()=>[
                            _createElementVNode("span", {
                                class: _normalizeClass(_ctx.$style['affiliate-stats__value'])
                            }, _toDisplayString(_ctx.balance), 3)
                        ]),
                    _: 1
                }),
                _createVNode(VInformer, {
                    class: _normalizeClass(_ctx.$style['affiliate-stats__registration']),
                    onClick: onRegClick
                }, {
                    label: _withCtx(()=>[
                            _createElementVNode("span", {
                                class: _normalizeClass(_ctx.$style['affiliate-stats__label'])
                            }, _toDisplayString(_ctx.$t('WEB2_AFFILIATE_REG')), 3)
                        ]),
                    value: _withCtx(()=>[
                            _createElementVNode("span", {
                                class: _normalizeClass({
                                    [_ctx.$style['affiliate-stats__value']]: true,
                                    [_ctx.$style['affiliate-stats__value--brand-color']]: true
                                })
                            }, _toDisplayString(_ctx.players), 3)
                        ]),
                    _: 1
                }, 8, [
                    "class"
                ]),
                _createVNode(VInformer, null, {
                    label: _withCtx(()=>[
                            _createElementVNode("span", {
                                class: _normalizeClass(_ctx.$style['affiliate-stats__label'])
                            }, _toDisplayString(_ctx.$t('WEB2_AFFILIATE_PROFIT')), 3)
                        ]),
                    value: _withCtx(()=>[
                            _createElementVNode("span", {
                                class: _normalizeClass(_ctx.$style['affiliate-stats__value'])
                            }, _toDisplayString(_ctx.profit), 3)
                        ]),
                    _: 1
                }),
                _createVNode(VInformer, null, {
                    label: _withCtx(()=>[
                            _createElementVNode("span", {
                                class: _normalizeClass(_ctx.$style['affiliate-stats__label'])
                            }, _toDisplayString(_ctx.$t('WEB2_AFFILIATE_PROFIT_RATE')), 3)
                        ]),
                    value: _withCtx(()=>[
                            _createElementVNode("span", {
                                class: _normalizeClass(_ctx.$style['affiliate-stats__value'])
                            }, _toDisplayString(profitRateFormatted.value), 3)
                        ]),
                    _: 1
                })
            ], 2)), [
                [
                    _directive_auto_id,
                    'AffiliateStats'
                ]
            ]);
        };
    }
});
