import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { NativeSelect } from 'web/src/components/Select';
import { VLoaderDelayed } from 'web/src/components/Loader';
import VTabs from 'web/src/components/Tabs/VTabs/VTabs.vue';
import getAffiliateFullTimeFormat from 'web/src/modules/affiliates/utils/getAffiliateFullTimeFormat';
import { useAffiliateRegistrationsRouteComposable } from 'web/src/modules/affiliates/pages/AffiliateRegistrationsRouteComponent/composables';
import AffiliateCustomerCard from 'web/src/modules/affiliates/components/AffiliateCustomerCard/AffiliateCustomerCard.vue';
import NotFoundMessage from 'web/src/modules/affiliates/components/NotFoundMessage/NotFoundMessage.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'AffiliateRegistrationsRouteComponent',
    props: {
        isWidget: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const { dateRangeSelectProperties, bonusCodesSelectProperties, tabsProperties, isAffiliatedCustomersLoading, affiliatedCustomersData, counterText, onBonusCodesFilterChange, onTabClick, onDateFilterChange } = useAffiliateRegistrationsRouteComposable(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['affiliate-customer-mobile'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['affiliate-customer-mobile__headline'])
                }, [
                    _ctx.isWidget ? _createCommentVNode("", true) : (_openBlock(), _createBlock(VTabs, _mergeProps({
                        key: 0
                    }, _unref(tabsProperties), {
                        class: _ctx.$style['affiliate-customer-mobile__tabs'],
                        "disable-click-effects": "",
                        "full-width": false,
                        onTabClick: _unref(onTabClick)
                    }), null, 16, [
                        "class",
                        "full-width",
                        "onTabClick"
                    ])),
                    _ctx.isWidget ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: _normalizeClass(_ctx.$style['affiliate-customer-mobile__select-group'])
                    }, [
                        _createVNode(_unref(NativeSelect), _mergeProps({
                            class: [
                                "affiliate-customer-mobile__select",
                                _ctx.$style['affiliate-customer-mobile__select']
                            ]
                        }, _unref(dateRangeSelectProperties), {
                            onChange: _unref(onDateFilterChange)
                        }), null, 16, [
                            "class",
                            "onChange"
                        ]),
                        _createVNode(_unref(NativeSelect), _mergeProps({
                            class: [
                                "affiliate-customer-mobile__select",
                                _ctx.$style['affiliate-customer-mobile__select']
                            ]
                        }, _unref(bonusCodesSelectProperties), {
                            onChange: _unref(onBonusCodesFilterChange)
                        }), null, 16, [
                            "class",
                            "onChange"
                        ])
                    ], 2))
                ], 2),
                _unref(isAffiliatedCustomersLoading) && !_ctx.isWidget ? (_openBlock(), _createBlock(_unref(VLoaderDelayed), {
                    key: 0
                })) : _unref(affiliatedCustomersData) ? (_openBlock(), _createElementBlock(_Fragment, {
                    key: 1
                }, [
                    _ctx.isWidget ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['affiliate-customer__counter'])
                    }, [
                        _createElementVNode("span", {
                            class: _normalizeClass(_ctx.$style['affiliate-customer__counter__label'])
                        }, _toDisplayString(_unref(counterText)), 3)
                    ], 2)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(affiliatedCustomersData), (affiliateCustomer, index)=>(_openBlock(), _createBlock(AffiliateCustomerCard, {
                            key: index
                        }, {
                            "top-left": _withCtx(()=>[
                                    _createTextVNode(_toDisplayString(_ctx.$t('WEB2_AFFILIATE_BONUS_CODE')), 1)
                                ]),
                            "bottom-left": _withCtx(()=>[
                                    _createTextVNode(_toDisplayString(affiliateCustomer.login), 1)
                                ]),
                            "top-right": _withCtx(()=>[
                                    _createTextVNode(_toDisplayString(_unref(getAffiliateFullTimeFormat)(affiliateCustomer.createdAt)), 1)
                                ]),
                            "bottom-right": _withCtx(()=>[
                                    _createTextVNode(_toDisplayString(affiliateCustomer.bonusCode || '-'), 1)
                                ]),
                            _: 2
                        }, 1024))), 128))
                ], 64)) : _createCommentVNode("", true),
                _unref(affiliatedCustomersData).length ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock(_Fragment, {
                    key: 2
                }, [
                    _ctx.isWidget ? (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: _normalizeClass(_ctx.$style['affiliate-customer__not-found'])
                    }, _toDisplayString(_ctx.$t('WEB2_AFFILIATE_REGISTRATIONS_WIDGET_NOT_FOUND')), 3)) : (_openBlock(), _createBlock(NotFoundMessage, {
                        key: 0,
                        text: _ctx.$t('WEB2_AFFILIATE_REGISTRATIONS_NOT_FOUND')
                    }, null, 8, [
                        "text"
                    ]))
                ], 64))
            ], 2)), [
                [
                    _directive_auto_id,
                    'AffiliateRegistrationsRouteComponent'
                ]
            ]);
        };
    }
});
