import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives } from "vue";
import { computed, onMounted, ref, toRef } from 'vue';
import { IconName } from '@leon-hub/icons';
import { nextAnimationFrame } from '@leon-hub/html-utils';
import { VForm } from 'web/src/components/Form';
import ConfirmModal from 'web/src/modules/dialogs/views/ConfirmModal/ConfirmModal.vue';
import { DialogAction } from 'web/src/modules/dialogs/enums';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { JumbotronIconKind } from 'web/src/components/Jumbotron';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useAffiliateStore } from 'web/src/modules/affiliates/store';
export default /*@__PURE__*/ _defineComponent({
    __name: 'AddBonusCodeModal',
    props: {
        minLength: {
            default: 5
        },
        maxLength: {
            default: 200
        }
    },
    emits: [
        "close"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const form = ref();
        const isLoading = ref(false);
        const isDone = ref(false);
        const { $translate } = useI18n();
        const affiliateStore = useAffiliateStore();
        const lastAddedBonusCode = toRef(affiliateStore, 'lastAddedBonusCode');
        const addBonusCodeError = toRef(affiliateStore, 'addBonusCodeError');
        const hintMessageProps = computed(()=>({
                LIMIT: `${props.minLength}`
            }));
        const hintMessageValue = computed(()=>$translate('WEB2_MINIMUM_SYMBOL_LIMIT', hintMessageProps).value);
        const uiSchema = computed(()=>({
                submitButton: null,
                fields: {
                    bonusCode: {
                        title: $translate('WEB2_BONUS_CODE_INPUT_LABEL').value,
                        hintMessage: hintMessageValue.value
                    }
                }
            }));
        const schema = computed(()=>({
                $schema: 'http://json-schema.org/draft-07/schema#',
                type: 'object',
                required: [
                    'bonusCode'
                ],
                properties: {
                    bonusCode: {
                        type: 'string',
                        maxLength: props.maxLength,
                        minLength: props.minLength
                    }
                }
            }));
        const customErrors = computed(()=>{
            if (!addBonusCodeError.value) return;
            return {
                bonusCode: [
                    {
                        message: addBonusCodeError.value,
                        value: lastAddedBonusCode.value
                    }
                ]
            };
        });
        const modalProperties = computed(()=>{
            const buttons = [];
            if (!isDone.value) buttons.push({
                action: DialogAction.CONFIRM,
                label: $translate('WEB2_ADD_CODE_MODAL_BUTTON_ADD').value,
                isLoading: isLoading.value
            });
            buttons.push({
                action: DialogAction.MODAL_CLOSE,
                kind: isDone.value ? ButtonKind.PRIMARY : ButtonKind.TRANSPARENT,
                label: isDone.value ? $translate('JSP_PCL_FBOT_CLOSE').value : $translate('JSP_MOBILE_CANCEL').value
            });
            return {
                iconName: isDone.value ? IconName.CHECK_OUTLINE : void 0,
                iconKind: isDone.value ? JumbotronIconKind.SUCCESS : void 0,
                title: isDone.value ? $translate('WEB2_ADD_BONUS_CODE_SUCCESS_CAPTION').value : $translate('WEB2_ADD_BONUS_CODE_CAPTION').value,
                confirmMessage: isDone.value ? void 0 : $translate('WEB2_ADD_BONUS_CODE_DESCRIPTION').value,
                buttons,
                isInnerModal: true,
                isProfileLayout: true
            };
        });
        onMounted(async ()=>{
            await nextAnimationFrame();
            form.value?.focusByName('bonusCode');
        });
        function closeModal() {
            emits('close');
        }
        function buttonClickSubmit() {
            form.value?.submit();
        }
        function onButtonClick(param) {
            let { action } = param;
            if (action === DialogAction.CONFIRM) buttonClickSubmit();
            if (action === DialogAction.MODAL_CLOSE) closeModal();
        }
        async function formSubmit(data) {
            isLoading.value = true;
            const value = data.formData.bonusCode;
            if (!value) {
                isLoading.value = false;
                return;
            }
            // eslint-disable-next-line @typescript-eslint/no-base-to-string
            const isSuccess = await affiliateStore.createBonusCode(`${value}`);
            isLoading.value = false;
            if (isSuccess) {
                isDone.value = true;
                affiliateStore.getBonusCodesHandler();
            }
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(ConfirmModal, {
                modal: modalProperties.value,
                onButtonClick: onButtonClick,
                onClose: closeModal
            }, {
                default: _withCtx(()=>[
                        isDone.value ? (_openBlock(), _createElementBlock("div", {
                            key: 1,
                            class: _normalizeClass(_ctx.$style['add-bonus-code__code'])
                        }, _toDisplayString(lastAddedBonusCode.value), 3)) : (_openBlock(), _createBlock(_unref(VForm), {
                            key: 0,
                            ref_key: "form",
                            ref: form,
                            "custom-errors": customErrors.value,
                            "ui-schema": uiSchema.value,
                            schema: schema.value,
                            "is-pending": isLoading.value,
                            "is-from-modal": "",
                            onSubmit: formSubmit
                        }, null, 8, [
                            "custom-errors",
                            "ui-schema",
                            "schema",
                            "is-pending"
                        ]))
                    ]),
                _: 1
            }, 8, [
                "modal"
            ])), [
                [
                    _directive_auto_id,
                    'AddBonusCodeModal'
                ]
            ]);
        };
    }
});
