import { computed, onMounted, ref, toRef } from 'vue';
import { useAffiliateStore } from 'web/src/modules/affiliates/store';
import { useI18n, useI18nLocale } from 'web/src/modules/i18n/composables';
import { BonusCodes, AffiliatedTabsFilter } from 'web/src/modules/affiliates/pages/AffiliateRegistrationsRouteComponent/enums';
import { TabsType } from 'web/src/components/Tabs/VTabs/enums';
import LanguageMicroSettings from 'web/src/utils/LanguageMicroSettings';
import { DateRangeDefaultDay } from 'web/src/modules/affiliates/store/enums';
import { dateRangeFilterOptionsList } from 'web/src/modules/affiliates/store/utils';
export default function useAffiliateRegistrationsRouteComposable(props) {
    const affiliateStore = useAffiliateStore();
    const isAffiliatedCustomersLoading = toRef(affiliateStore, 'isAffiliatedCustomersLoading');
    const affiliatedCustomers = toRef(affiliateStore, 'affiliatedCustomers');
    const lastRegisteredAffiliatedCustomers = toRef(affiliateStore, 'lastRegisteredAffiliatedCustomers');
    const { $translate } = useI18n();
    const { locale } = useI18nLocale();
    const isDepositedOnly = ref(false);
    const counter = ref(0);
    const range = ref(DateRangeDefaultDay.ThirtyDays);
    const bonusCode = ref(BonusCodes.ALL);
    const tabsProperties = computed(()=>({
            items: [
                {
                    id: AffiliatedTabsFilter.ALL,
                    label: $translate('WEB2_AFFILIATE_ALL_REGISTRATIONS').value
                },
                {
                    id: AffiliatedTabsFilter.DEPOSITARY,
                    label: $translate('WEB2_AFFILIATE_DEPOSITARY').value
                }
            ],
            activeId: isDepositedOnly.value ? AffiliatedTabsFilter.DEPOSITARY : AffiliatedTabsFilter.ALL,
            type: TabsType.UPPERCASE
        }));
    const dateRangeFilterOptions = computed(()=>dateRangeFilterOptionsList());
    const dateRangeSelectProperties = computed(()=>({
            name: 'dateSelect',
            options: dateRangeFilterOptions.value,
            selectedValue: range.value
        }));
    const bonusCodesFilterOptions = computed(()=>{
        const bonusCodes = affiliatedCustomers.value?.map((item)=>item?.bonusCode || BonusCodes.WM);
        const uniqueBonusCodes = [
            ...new Set(bonusCodes)
        ].map((code)=>({
                label: code,
                value: code
            }));
        return [
            {
                label: $translate('WEB2_AFFILIATE_ALL_BONUS_CODES').value,
                value: BonusCodes.ALL
            },
            ...uniqueBonusCodes
        ];
    });
    const bonusCodesSelectProperties = computed(()=>({
            name: 'codesSelect',
            options: bonusCodesFilterOptions.value,
            selectedValue: bonusCode.value
        }));
    const affiliatedCustomersData = computed(()=>{
        if (props.isWidget) return addNumbers(lastRegisteredAffiliatedCustomers.value);
        if (bonusCode.value !== BonusCodes.ALL) {
            let filter = (item)=>item.bonusCode === bonusCode.value;
            if (bonusCode.value === BonusCodes.WM) filter = (item)=>null === item.bonusCode;
            return addNumbers(affiliatedCustomers.value?.filter((element)=>filter(element)));
        }
        return addNumbers(affiliatedCustomers.value);
    });
    const counterTextOptions = computed(()=>({
            count: String(counter.value)
        }));
    const counterText = computed(()=>LanguageMicroSettings.plural([
            $translate('WEB2_PLURAL_REGISTRATIONS_ONE', counterTextOptions).value,
            $translate('WEB2_PLURAL_REGISTRATIONS_TWO', counterTextOptions).value,
            $translate('WEB2_PLURAL_REGISTRATIONS_MANY', counterTextOptions).value
        ], counter.value, locale.value));
    onMounted(getAffiliateCustomers);
    function getAffiliateCustomers() {
        affiliateStore.fetchAffiliateCustomers({
            isDepositedOnly: isDepositedOnly.value,
            range: range.value,
            bonusCode: ''
        });
    }
    function addNumbers(customersArray) {
        if (!customersArray) return [];
        let count = customersArray.length;
        counter.value = customersArray.length;
        return customersArray.map((item)=>{
            const updatedItem = {
                login: count.toString(),
                bonusCode: item.bonusCode,
                createdAt: item.createdAt
            };
            count -= 1;
            return updatedItem;
        });
    }
    function onTabClick(tabId) {
        isDepositedOnly.value = tabId === AffiliatedTabsFilter.DEPOSITARY;
        getAffiliateCustomers();
    }
    function onDateFilterChange(event) {
        range.value = event.target.value;
        bonusCode.value = BonusCodes.ALL;
        getAffiliateCustomers();
    }
    function onBonusCodesFilterChange(event) {
        bonusCode.value = event.target.value;
        getAffiliateCustomers();
    }
    return {
        dateRangeSelectProperties,
        bonusCodesSelectProperties,
        tabsProperties,
        isAffiliatedCustomersLoading,
        affiliatedCustomersData,
        counterText,
        onBonusCodesFilterChange,
        onTabClick,
        onDateFilterChange
    };
}
