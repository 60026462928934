import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "affiliate-program"
};
const _hoisted_2 = {
    key: 1
};
const _hoisted_3 = {
    key: 0,
    class: "affiliate-program__reg"
};
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { Tag } from '@leon-hub/tags';
import RouteName from '@leon-hub/routing-config';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import VCmsContent from 'web/src/modules/cms/components/VCmsContent/VCmsContent.vue';
import { VPreloader } from 'web/src/components/Preloader';
import { ButtonHeight, ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useCmsStore } from 'web/src/modules/cms/store';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'AffiliateProgramRoutePage',
    setup (__props) {
        const cmsStore = useCmsStore();
        const isLoading = ref(true);
        const { isLoggedIn } = useIsLoggedIn();
        const router = useRouter();
        onMounted(async ()=>{
            await cmsStore.fetchCmsContentTranslation({
                key: 'WEB2_AFFILIATE_PROGRAM'
            });
            isLoading.value = false;
        });
        function handleRegistrationClick() {
            router.push({
                name: RouteName.REGISTRATION
            });
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                isLoading.value ? (_openBlock(), _createBlock(_unref(VPreloader), {
                    key: 0,
                    class: "affiliate-program__loader"
                })) : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(VCmsContent, {
                        class: "affiliate-program__content",
                        "cms-key": "WEB2_AFFILIATE_PROGRAM",
                        "no-padding": "",
                        silent: ""
                    }),
                    _unref(isLoggedIn) ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createVNode(VButton, {
                            label: _ctx.$t('JSPNAV_USTAT_REGISTER'),
                            tag: _unref(Tag).A,
                            href: _unref(router).resolve({
                                name: _unref(RouteName).REGISTRATION
                            }).href,
                            height: _unref(ButtonHeight).SMALL,
                            kind: _unref(ButtonKind).PRIMARY,
                            "full-width": false,
                            onClick: _withModifiers(handleRegistrationClick, [
                                "prevent"
                            ])
                        }, null, 8, [
                            "label",
                            "tag",
                            "href",
                            "height",
                            "kind",
                            "full-width"
                        ])
                    ]))
                ]))
            ])), [
                [
                    _directive_auto_id,
                    'AffiliateProgramRoutePage'
                ]
            ]);
        };
    }
});
