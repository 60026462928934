import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { toRef } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { openDocument } from '@leon-hub/cordova';
import { VIcon } from '@components/v-icon';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useSiteConfigStore } from 'web/src/modules/core/store';
export default /*@__PURE__*/ _defineComponent({
    __name: 'AffiliateProfileMigratedRoutePage',
    setup (__props) {
        const siteConfigStore = useSiteConfigStore();
        const affiliateMigrationLinkRef = toRef(siteConfigStore, 'affiliateMigrationLink');
        function onClick() {
            window.open(affiliateMigrationLinkRef.value, '_self');
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['affiliate-profile-migrated'])
            }, [
                _createVNode(_unref(VIcon), {
                    class: _normalizeClass(_ctx.$style['affiliate-profile-migrated__icon']),
                    name: _unref(IconName).ATTENTION_OUTLINE,
                    size: _unref(IconSize).SIZE_50
                }, null, 8, [
                    "class",
                    "name",
                    "size"
                ]),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['affiliate-profile-migrated__caption'])
                }, _toDisplayString(_ctx.$t('WEB2_MODALTITLE_ATTENTION')), 3),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['affiliate-profile-migrated__text'])
                }, _toDisplayString(_ctx.$t('WEB2_AFFILIATE_PROFILE_MIGRATED_TEXT')), 3),
                _createVNode(VButton, {
                    label: _ctx.$t('WEB2_EXTERNAL_LINK_OPEN_BTN'),
                    "full-width": "",
                    kind: _unref(ButtonKind).PRIMARY,
                    onClick: onClick
                }, null, 8, [
                    "label",
                    "kind"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'AffiliateProfileMigratedRoutePage'
                ]
            ]);
        };
    }
});
