import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, toDisplayString as _toDisplayString, unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { computed, toRef } from 'vue';
import { getAffiliateLink } from 'web/src/modules/affiliates/utils/getAffiliateLink';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import VCmsContent from 'web/src/modules/cms/components/VCmsContent/VCmsContent.vue';
import { useCopyToClipboard } from 'web/src/modules/dialogs/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'AffiliateLinkHelpRoutePage',
    setup (__props) {
        const customerDataStore = useCustomerDataStore();
        const scgStore = useSiteConfigStore();
        const login = toRef(customerDataStore, 'login');
        const linkCdnUrl = toRef(scgStore, 'linkCdnUrl');
        const { copy } = useCopyToClipboard();
        const affiliateLink = computed(()=>getAffiliateLink({
                base: linkCdnUrl.value,
                login: login.value
            }));
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['affiliate-help'])
            }, [
                _createVNode(VCmsContent, {
                    "cms-key": "WEB2_AFFILIATE_LINK_HELP_DESCRIPTION",
                    "no-padding": ""
                }),
                _createElementVNode("p", null, [
                    _createElementVNode("u", {
                        class: _normalizeClass(_ctx.$style['affiliate-help__link']),
                        onClick: _cache[0] || (_cache[0] = ($event)=>_unref(copy)({
                                data: affiliateLink.value,
                                notificationText: _ctx.$t('WEB2_LINK_HAS_BEEN_COPIED')
                            }))
                    }, _toDisplayString(affiliateLink.value), 3),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('WEB2_AFFILIATE_LINK_HELP_DESCRIPTION_SECOND')), 1)
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'AffiliateLinkHelpRoutePage'
                ]
            ]);
        };
    }
});
