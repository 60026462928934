import { defineComponent as _defineComponent } from 'vue';
import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { computed } from 'vue';
import DefaultTopBarRouteComponent from 'web/src/modules/core/views/DefaultTopBarRouteComponent/DefaultTopBarRouteComponent.vue';
import { useCurrency } from 'web/src/modules/money/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'AffiliateOperationsTopBarRouteComponent',
    props: {
        title: {}
    },
    setup (__props) {
        const props = __props;
        const { currency } = useCurrency();
        const topBarTitle = computed(()=>`${props.title} (${currency.value})`);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(DefaultTopBarRouteComponent, {
                title: topBarTitle.value
            }, null, 8, [
                "title"
            ])), [
                [
                    _directive_auto_id,
                    'AffiliateOperationsTopBarRouteComponent'
                ]
            ]);
        };
    }
});
