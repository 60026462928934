import { defineComponent as _defineComponent } from 'vue';
import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { computed } from 'vue';
import ConfirmModal from 'web/src/modules/dialogs/views/ConfirmModal/ConfirmModal.vue';
import { DialogAction } from 'web/src/modules/dialogs/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useCopyToClipboard } from 'web/src/modules/dialogs/composables';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CopyLinkModal',
    props: {
        bonusCodeItem: {}
    },
    emits: [
        "close"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { $translate } = useI18n();
        const { copy } = useCopyToClipboard();
        const modalProperties = computed(()=>({
                title: $translate('WEB2_BONUS_CODE').value,
                confirmMessage: props.bonusCodeItem.bonusCode,
                isProfileLayout: true,
                buttons: [
                    {
                        action: DialogAction.CONFIRM,
                        label: $translate('WEB2_COPY_LINK').value,
                        kind: ButtonKind.PRIMARY
                    },
                    {
                        action: DialogAction.SECONDARY_CLICK,
                        label: $translate('WEB2_COPY_CODE').value,
                        kind: ButtonKind.SECONDARY
                    }
                ]
            }));
        function copyLink() {
            copy({
                data: props.bonusCodeItem.link,
                notificationText: $translate('WEB2_LINK_HAS_BEEN_COPIED').value
            });
            emitClose();
        }
        function copyCode() {
            copy({
                data: props.bonusCodeItem.bonusCode,
                notificationText: $translate('WEB2_CODE_HAS_BEEN_COPIED').value
            });
            emitClose();
        }
        function onButtonClick(param) {
            let { action } = param;
            if (action === DialogAction.CONFIRM) copyLink();
            if (action === DialogAction.SECONDARY_CLICK) copyCode();
        }
        function emitClose() {
            emits('close');
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(ConfirmModal, {
                modal: modalProperties.value,
                onButtonClick: onButtonClick,
                onClose: emitClose
            }, null, 8, [
                "modal"
            ])), [
                [
                    _directive_auto_id,
                    'CopyLinkModal'
                ]
            ]);
        };
    }
});
