import { computed, onBeforeUnmount, toRef } from 'vue';
import { useAffiliateStore } from 'web/src/modules/affiliates/store';
import { useI18n } from 'web/src/modules/i18n/composables';
import { mapOperationDateTime, mapOperationTypeId, mapZeroToEmptyString } from 'web/src/modules/affiliates/submodules/operations-history/utils';
export default function useOperationHistoryDetailsRoutePage() {
    const affiliateStore = useAffiliateStore();
    const selectedOperation = toRef(affiliateStore, 'selectedOperation');
    const { $translate } = useI18n();
    const dateTime = computed(()=>mapOperationDateTime(selectedOperation.value?.date));
    const typeId = computed(()=>mapOperationTypeId($translate, selectedOperation.value?.typeId));
    const pendingDebit = computed(()=>mapZeroToEmptyString(selectedOperation.value?.pendingDebit));
    const pendingCredit = computed(()=>mapZeroToEmptyString(selectedOperation.value?.pendingCredit));
    const pendingBalance = computed(()=>mapZeroToEmptyString(selectedOperation.value?.pendingBalance));
    const credit = computed(()=>mapZeroToEmptyString(selectedOperation.value?.credit));
    onBeforeUnmount(affiliateStore.clearSelectedOperation);
    return {
        dateTime,
        typeId,
        pendingDebit,
        pendingCredit,
        pendingBalance,
        credit,
        selectedOperation
    };
}
