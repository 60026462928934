import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useCopyToClipboard } from 'web/src/modules/dialogs/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'AffiliateLink',
    props: {
        affiliateLink: {
            default: ''
        }
    },
    emits: [
        "help"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emits = __emit;
        const { copy } = useCopyToClipboard();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['affiliate-reference'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['affiliate-reference__title'])
                }, _toDisplayString(_ctx.$t('WEB2_AFFILIATE_MY_LINK')), 3),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['affiliate-reference__wrapper'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['affiliate-reference__wrapper-field'])
                    }, _toDisplayString(_ctx.affiliateLink), 3),
                    _createVNode(VButton, {
                        kind: _unref(ButtonKind).PRIMARY,
                        "icon-name": _unref(IconName).FILES,
                        class: _normalizeClass(_ctx.$style['affiliate-reference__wrapper-copy']),
                        onClick: _cache[0] || (_cache[0] = ($event)=>_unref(copy)({
                                data: _ctx.affiliateLink,
                                notificationText: _ctx.$t('WEB2_LINK_HAS_BEEN_COPIED'),
                                isProfileModalTooltip: true
                            }))
                    }, null, 8, [
                        "kind",
                        "icon-name",
                        "class"
                    ])
                ], 2),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['affiliate-reference__info']),
                    onClick: _cache[1] || (_cache[1] = ($event)=>emits('help'))
                }, [
                    _createVNode(_unref(VIcon), {
                        class: _normalizeClass(_ctx.$style['affiliate-reference__icon']),
                        size: _unref(IconSize).SIZE_16,
                        name: _unref(IconName).QUESTION_OUTLINE
                    }, null, 8, [
                        "class",
                        "size",
                        "name"
                    ]),
                    _createElementVNode("span", {
                        class: _normalizeClass(_ctx.$style['affiliate-reference__info-text'])
                    }, _toDisplayString(_ctx.$t('WEB2_AFFILIATE_LINK_INFO')), 3)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'AffiliateLink'
                ]
            ]);
        };
    }
});
