import { defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createBlock as _createBlock, mergeProps as _mergeProps, createVNode as _createVNode, normalizeClass as _normalizeClass, unref as _unref, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue";
import { computed, onMounted, toRef, watch } from 'vue';
import { useRouter } from 'vue-router';
import GqlApiBatchedSubRequestError from '@leon-hub/api/src/client/graphql/errors/GqlApiBatchedSubRequestError';
import RouteName from '@leon-hub/routing-config-names';
import { AffiliatePartnerStatus } from '@leon-hub/api-sdk';
import { useAffiliateStore } from 'web/src/modules/affiliates/store';
import { useNavigationItemsStore, useSiteConfigStore } from 'web/src/modules/core/store';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { getAffiliateLink } from 'web/src/modules/affiliates/utils/getAffiliateLink';
import AffiliateStats from 'web/src/modules/affiliates/components/AffiliateStats/AffiliateStats.vue';
import VPreloader from 'web/src/components/Preloader/VPreloader/VPreloader.vue';
import BonusCodesOverview from 'web/src/modules/affiliates/components/BonusCodesOverview/BonusCodesOverview.vue';
import AffiliateActivation from 'web/src/modules/affiliates/components/AffiliateActivation/AffiliateActivation.vue';
import NavList from 'web/src/modules/profile/components/NavList';
import { AffiliateErrors } from 'web/src/modules/affiliates/enums';
import { DateRangeDefaultDay } from 'web/src/modules/affiliates/store/enums';
import AffiliateLink from 'web/src/modules/affiliates/components/AffiliateLink/AffiliateLink.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'AffiliateProfileRoutePage',
    setup (__props) {
        const customerDataStore = useCustomerDataStore();
        const scgStore = useSiteConfigStore();
        const navigationItemsStore = useNavigationItemsStore();
        const affiliateStore = useAffiliateStore();
        const router = useRouter();
        const affiliatePartnerStatus = toRef(customerDataStore, 'affiliatePartnerStatus');
        const login = toRef(customerDataStore, 'login');
        const affiliateNavigation = toRef(navigationItemsStore, 'affiliateNavigation');
        const bonusCodesCount = toRef(affiliateStore, 'bonusCodesCount');
        const maxBonusCodesCount = toRef(affiliateStore, 'maxBonusCodesCount');
        const stats = toRef(affiliateStore, 'stats');
        const linkCdnUrl = toRef(scgStore, 'linkCdnUrl');
        const isLoading = computed(()=>null === stats.value);
        const isAffiliateActivated = computed(()=>affiliatePartnerStatus.value === AffiliatePartnerStatus.YES);
        const isAffiliateClosed = computed(()=>affiliatePartnerStatus.value === AffiliatePartnerStatus.CLOSED);
        const players = computed(()=>stats.value?.affiliatedCustomersCount ?? 0);
        const affiliateStatsProperties = computed(()=>({
                balance: stats.value ? stats.value.pendingBalance : 0,
                profit: stats.value ? stats.value.profit : 0,
                profitRate: stats.value ? stats.value.percent : 0,
                players: players.value,
                affiliateRegistrationsPage: RouteName.AFFILIATE_REGISTRATIONS
            }));
        const affiliateLinkUrl = computed(()=>getAffiliateLink({
                base: linkCdnUrl.value,
                login: login.value
            }));
        onMounted(async ()=>{
            if (isAffiliateClosed.value) router.push({
                name: RouteName.HOME
            });
            if (isAffiliateActivated.value) try {
                await affiliateStore.getProfileGeneralStats({
                    range: DateRangeDefaultDay.ThirtyDays
                });
                await affiliateStore.getBonusCodesHandler();
            } catch (error) {
                if (error instanceof GqlApiBatchedSubRequestError && error.extensions.errorCode === AffiliateErrors.PROFILE_MIGRATED_ERROR) await router.replace({
                    name: RouteName.AFFILIATE_PROFILE_MIGRATED
                });
            }
        });
        watch(affiliatePartnerStatus, async (to)=>{
            if (to === AffiliatePartnerStatus.YES) {
                await affiliateStore.getProfileGeneralStats({
                    range: DateRangeDefaultDay.ThirtyDays
                });
                await affiliateStore.getBonusCodesHandler();
            }
        });
        function showBonusCodeView() {
            router.push({
                name: RouteName.AFFILIATE_BONUS_CODES
            });
        }
        function showBonusCodeHelpView() {
            router.push({
                name: RouteName.AFFILIATE_BONUS_CODES_HELP
            });
        }
        function showAffiliateLinkHelpView() {
            router.push({
                name: RouteName.AFFILIATE_LINK_HELP
            });
        }
        return (_ctx, _cache)=>isAffiliateActivated.value || isAffiliateClosed.value ? isLoading.value ? (_openBlock(), _createBlock(VPreloader, {
                key: 1
            })) : (_openBlock(), _createElementBlock("div", {
                key: 2,
                class: _normalizeClass(_ctx.$style['affiliate-profile'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['affiliate-profile__left'])
                }, [
                    _createVNode(AffiliateStats, _mergeProps({
                        class: _ctx.$style['affiliate-profile__stats']
                    }, affiliateStatsProperties.value), null, 16, [
                        "class"
                    ]),
                    _createVNode(BonusCodesOverview, {
                        class: _normalizeClass(_ctx.$style['affiliate-profile__overview']),
                        "button-is-link": "",
                        "current-count": bonusCodesCount.value,
                        "max-count": maxBonusCodesCount.value,
                        onButtonClick: showBonusCodeView,
                        onHelp: showBonusCodeHelpView
                    }, null, 8, [
                        "class",
                        "current-count",
                        "max-count"
                    ]),
                    _createVNode(_unref(NavList), {
                        class: _normalizeClass(_ctx.$style['affiliate-profile__nav']),
                        "one-level-list": "",
                        items: affiliateNavigation.value
                    }, null, 8, [
                        "class",
                        "items"
                    ]),
                    _createVNode(AffiliateLink, {
                        class: _normalizeClass(_ctx.$style['affiliate-profile__link']),
                        "affiliate-link": affiliateLinkUrl.value,
                        onHelp: showAffiliateLinkHelpView
                    }, null, 8, [
                        "class",
                        "affiliate-link"
                    ])
                ], 2)
            ], 2)) : (_openBlock(), _createBlock(AffiliateActivation, {
                key: 0
            }));
    }
});
