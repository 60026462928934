import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import AffiliateList from 'web/src/modules/affiliates/components/AffiliateList/AffiliateList.vue';
import AffiliateListItem from 'web/src/modules/affiliates/components/AffiliateListItem/AffiliateListItem.vue';
import { useOperationHistoryDetailsRoutePage } from 'web/src/modules/affiliates/submodules/operations-history/pages/OperationHistoryDetailsRoutePage/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'OperationHistoryDetailsRoutePage',
    setup (__props) {
        const { dateTime, typeId, pendingDebit, pendingCredit, pendingBalance, credit, selectedOperation } = useOperationHistoryDetailsRoutePage();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['affiliate-operation-details'])
            }, [
                _createVNode(AffiliateList, {
                    class: _normalizeClass(_ctx.$style['affiliate-operation-details__list'])
                }, {
                    default: _withCtx(()=>[
                            _createVNode(AffiliateListItem, {
                                class: _normalizeClass(_ctx.$style['affiliate-operation-details__list-item']),
                                "no-hover": ""
                            }, {
                                default: _withCtx(()=>[
                                        _createElementVNode("div", {
                                            class: _normalizeClass(_ctx.$style['affiliate-operation-details__list-item'])
                                        }, [
                                            _createElementVNode("span", {
                                                class: _normalizeClass(_ctx.$style['affiliate-operation-details__list-item__left'])
                                            }, _toDisplayString(`${_ctx.$t('WEB2_AFFILIATE_DATE')}:`), 3),
                                            _createElementVNode("span", {
                                                class: _normalizeClass(_ctx.$style['affiliate-operation-details__list-item__right'])
                                            }, _toDisplayString(_unref(dateTime)), 3)
                                        ], 2)
                                    ]),
                                _: 1
                            }, 8, [
                                "class"
                            ]),
                            _createVNode(AffiliateListItem, {
                                class: _normalizeClass(_ctx.$style['affiliate-operation-details__list-item']),
                                "no-hover": ""
                            }, {
                                default: _withCtx(()=>[
                                        _createElementVNode("div", {
                                            class: _normalizeClass(_ctx.$style['affiliate-operation-details__list-item'])
                                        }, [
                                            _createElementVNode("span", {
                                                class: _normalizeClass(_ctx.$style['affiliate-operation-details__list-item__left'])
                                            }, _toDisplayString(`${_ctx.$t('WEB2_AFFILIATE_TRANSACTION')}:`), 3),
                                            _createElementVNode("span", {
                                                class: _normalizeClass(_ctx.$style['affiliate-operation-details__list-item__right'])
                                            }, _toDisplayString(_unref(typeId)), 3)
                                        ], 2)
                                    ]),
                                _: 1
                            }, 8, [
                                "class"
                            ]),
                            _createVNode(AffiliateListItem, {
                                class: _normalizeClass(_ctx.$style['affiliate-operation-details__list-item']),
                                "no-hover": ""
                            }, {
                                default: _withCtx(()=>[
                                        _createElementVNode("div", {
                                            class: _normalizeClass(_ctx.$style['affiliate-operation-details__list-item'])
                                        }, [
                                            _createElementVNode("span", {
                                                class: _normalizeClass(_ctx.$style['affiliate-operation-details__list-item__left'])
                                            }, _toDisplayString(`${_ctx.$t('WEB2_AFFILIATE_FROM_PENDING_DEBIT_FULLTEXT')}:`), 3),
                                            _createElementVNode("span", {
                                                class: _normalizeClass({
                                                    [_ctx.$style['affiliate-operation-details__list-item__right']]: true,
                                                    [_ctx.$style['affiliate-operation-details__list-item__right-red']]: true
                                                })
                                            }, _toDisplayString(_unref(pendingDebit)), 3)
                                        ], 2)
                                    ]),
                                _: 1
                            }, 8, [
                                "class"
                            ]),
                            _createVNode(AffiliateListItem, {
                                class: _normalizeClass(_ctx.$style['affiliate-operation-details__list-item']),
                                "no-hover": ""
                            }, {
                                default: _withCtx(()=>[
                                        _createElementVNode("div", {
                                            class: _normalizeClass(_ctx.$style['affiliate-operation-details__list-item'])
                                        }, [
                                            _createElementVNode("span", {
                                                class: _normalizeClass(_ctx.$style['affiliate-operation-details__list-item__left'])
                                            }, _toDisplayString(`${_ctx.$t('WEB2_AFFILIATE_TO_PENDING_CREDIT_FULLTEXT')}:`), 3),
                                            _createElementVNode("span", {
                                                class: _normalizeClass({
                                                    [_ctx.$style['affiliate-operation-details__list-item__right']]: true,
                                                    [_ctx.$style['affiliate-operation-details__list-item__right-green']]: true
                                                })
                                            }, _toDisplayString(_unref(pendingCredit)), 3)
                                        ], 2)
                                    ]),
                                _: 1
                            }, 8, [
                                "class"
                            ]),
                            _createVNode(AffiliateListItem, {
                                class: _normalizeClass(_ctx.$style['affiliate-operation-details__list-item']),
                                "no-hover": ""
                            }, {
                                default: _withCtx(()=>[
                                        _createElementVNode("div", {
                                            class: _normalizeClass(_ctx.$style['affiliate-operation-details__list-item'])
                                        }, [
                                            _createElementVNode("span", {
                                                class: _normalizeClass(_ctx.$style['affiliate-operation-details__list-item__left'])
                                            }, _toDisplayString(`${_ctx.$t('WEB2_AFFILIATE_PENDING_BALANCE_FULLTEXT')}`), 3),
                                            _createElementVNode("span", {
                                                class: _normalizeClass(_ctx.$style['affiliate-operation-details__list-item__right'])
                                            }, _toDisplayString(_unref(pendingBalance)), 3)
                                        ], 2)
                                    ]),
                                _: 1
                            }, 8, [
                                "class"
                            ]),
                            _createVNode(AffiliateListItem, {
                                class: _normalizeClass(_ctx.$style['affiliate-operation-details__list-item']),
                                "no-hover": ""
                            }, {
                                default: _withCtx(()=>[
                                        _createElementVNode("div", {
                                            class: _normalizeClass(_ctx.$style['affiliate-operation-details__list-item'])
                                        }, [
                                            _createElementVNode("span", {
                                                class: _normalizeClass(_ctx.$style['affiliate-operation-details__list-item__left'])
                                            }, _toDisplayString(`${_ctx.$t('WEB2_AFFILIATE_TO_CREDIT')}`), 3),
                                            _createElementVNode("span", {
                                                class: _normalizeClass({
                                                    [_ctx.$style['affiliate-operation-details__list-item__right']]: true,
                                                    [_ctx.$style['affiliate-operation-details__list-item__right-green']]: true
                                                })
                                            }, _toDisplayString(_unref(credit)), 3)
                                        ], 2)
                                    ]),
                                _: 1
                            }, 8, [
                                "class"
                            ]),
                            _createVNode(AffiliateListItem, {
                                class: _normalizeClass(_ctx.$style['affiliate-operation-details__list-item']),
                                "no-hover": ""
                            }, {
                                default: _withCtx(()=>[
                                        _createElementVNode("div", {
                                            class: _normalizeClass(_ctx.$style['affiliate-operation-details__list-item'])
                                        }, [
                                            _createElementVNode("span", {
                                                class: _normalizeClass(_ctx.$style['affiliate-operation-details__list-item__left'])
                                            }, _toDisplayString(`${_ctx.$t('WEB2_AFFILIATE_BALANCE')}:`), 3),
                                            _createElementVNode("span", {
                                                class: _normalizeClass(_ctx.$style['affiliate-operation-details__list-item__right'])
                                            }, _toDisplayString(_unref(selectedOperation)?.balance), 3)
                                        ], 2)
                                    ]),
                                _: 1
                            }, 8, [
                                "class"
                            ])
                        ]),
                    _: 1
                }, 8, [
                    "class"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'OperationHistoryDetailsRoutePage'
                ]
            ]);
        };
    }
});
