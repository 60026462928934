import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import VCmsContent from 'web/src/modules/cms/components/VCmsContent/VCmsContent.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'AffiliateBonusCodesHelpRoutePage',
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['affiliate-help'])
            }, [
                _createVNode(VCmsContent, {
                    "cms-key": "WEB2_AFFILIATE_BONUS_CODES_HELP_DESCRIPTION",
                    "no-padding": "",
                    silent: ""
                })
            ], 2)), [
                [
                    _directive_auto_id,
                    'AffiliateBonusCodesHelpRoutePage'
                ]
            ]);
        };
    }
});
