import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    key: 1
};
const _hoisted_2 = [
    "onClick"
];
import { computed } from 'vue';
import { IconName } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import AffiliateList from 'web/src/modules/affiliates/components/AffiliateList/AffiliateList.vue';
import AffiliateListItem from 'web/src/modules/affiliates/components/AffiliateListItem/AffiliateListItem.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'BonusCodesList',
    props: {
        list: {}
    },
    emits: [
        "trigger-copy"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const isEmpty = computed(()=>!props.list?.length);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['bonus-codes-list'])
            }, [
                _createElementVNode("p", {
                    class: _normalizeClass(_ctx.$style['bonus-codes-list__caption'])
                }, _toDisplayString(_ctx.$t('WEB2_MY_BONUS_CODES')), 3),
                isEmpty.value ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['bonus-codes-list__empty-description'])
                }, _toDisplayString(_ctx.$t('WEB2_EMPTY_CODES_DESCRIPTION')), 3)) : (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createVNode(AffiliateList, {
                        class: _normalizeClass(_ctx.$style['bonus-codes-list__body'])
                    }, {
                        default: _withCtx(()=>[
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item)=>(_openBlock(), _createBlock(AffiliateListItem, {
                                        key: item.id,
                                        class: _normalizeClass(_ctx.$style['bonus-codes-list__item'])
                                    }, {
                                        default: _withCtx(()=>[
                                                _createElementVNode("button", {
                                                    type: "button",
                                                    class: _normalizeClass(_ctx.$style['bonus-codes-list__button']),
                                                    onClick: ($event)=>emits('trigger-copy', item)
                                                }, [
                                                    _createElementVNode("span", {
                                                        class: _normalizeClass(_ctx.$style['bonus-codes-list__button-text'])
                                                    }, _toDisplayString(item.bonusCode), 3),
                                                    _createVNode(_unref(VIcon), {
                                                        name: _unref(IconName).FILES,
                                                        class: _normalizeClass(_ctx.$style['bonus-codes-list__button-icon'])
                                                    }, null, 8, [
                                                        "name",
                                                        "class"
                                                    ])
                                                ], 10, _hoisted_2)
                                            ]),
                                        _: 2
                                    }, 1032, [
                                        "class"
                                    ]))), 128))
                            ]),
                        _: 1
                    }, 8, [
                        "class"
                    ])
                ]))
            ], 2)), [
                [
                    _directive_auto_id,
                    'BonusCodesList'
                ]
            ]);
        };
    }
});
