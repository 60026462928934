import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { onMounted, ref, toRef } from 'vue';
import { useRouter } from 'vue-router';
import RouteName from '@leon-hub/routing-config';
import { VLoaderMargin } from '@components/loader';
import ModalPortal from 'web/src/components/Modal/ModalPortal/ModalPortal.vue';
import BonusCodesOverview from 'web/src/modules/affiliates/components/BonusCodesOverview/BonusCodesOverview.vue';
import CopyLinkModal from 'web/src/modules/affiliates/components/CopyLinkModal/CopyLinkModal.vue';
import AddBonusCodeModal from 'web/src/modules/affiliates/components/AddBonusCodeModal/AddBonusCodeModal.vue';
import { VLoaderDelayed } from 'web/src/components/Loader';
import BonusCodesList from 'web/src/modules/affiliates/components/BonusCodesList/BonusCodesList.vue';
import { useAffiliateStore } from 'web/src/modules/affiliates/store';
import { ModalSelector } from 'web/src/modules/core/enums';
export default /*@__PURE__*/ _defineComponent({
    __name: 'AffiliateBonusCodesRoutePage',
    setup (__props) {
        const affiliateStore = useAffiliateStore();
        const bonusCodesLoaded = toRef(affiliateStore, 'bonusCodesLoaded');
        const maxBonusCodesCount = toRef(affiliateStore, 'maxBonusCodesCount');
        const bonusCodesCount = toRef(affiliateStore, 'bonusCodesCount');
        const bonusCodes = toRef(affiliateStore, 'bonusCodes');
        const isAddBonusModalShown = ref(false);
        const isCopyLinkModalShown = ref(false);
        const bonusCodeItem = ref({
            id: 0,
            bonusCode: '',
            link: ''
        });
        const router = useRouter();
        onMounted(()=>affiliateStore.getBonusCodesHandler());
        function showAddModal() {
            isAddBonusModalShown.value = true;
        }
        function showCopyLinkModal(item) {
            bonusCodeItem.value = item;
            isCopyLinkModalShown.value = true;
        }
        function showHelp() {
            router.push({
                name: RouteName.AFFILIATE_BONUS_CODES_HELP
            });
        }
        function onAddBonusModalClose() {
            isAddBonusModalShown.value = false;
        }
        function onCopyLinkModalClose() {
            isCopyLinkModalShown.value = false;
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['modal-content-inner'])
            }, [
                bonusCodesLoaded.value ? (_openBlock(), _createElementBlock(_Fragment, {
                    key: 1
                }, [
                    _createVNode(BonusCodesOverview, {
                        "add-button-text": _ctx.$t('WEB2_CREATE_BONUS_CODE'),
                        "current-count": bonusCodesCount.value,
                        "max-count": maxBonusCodesCount.value,
                        class: _normalizeClass(_ctx.$style['bonus-code-overview']),
                        onButtonClick: showAddModal,
                        onHelp: showHelp
                    }, null, 8, [
                        "add-button-text",
                        "current-count",
                        "max-count",
                        "class"
                    ]),
                    _createVNode(BonusCodesList, {
                        list: bonusCodes.value,
                        onTriggerCopy: showCopyLinkModal
                    }, null, 8, [
                        "list"
                    ]),
                    _createVNode(ModalPortal, {
                        selector: _unref(ModalSelector).DESKTOP_INNER_MODAL
                    }, {
                        default: _withCtx(()=>[
                                isAddBonusModalShown.value ? (_openBlock(), _createBlock(AddBonusCodeModal, {
                                    key: 0,
                                    "min-length": 5,
                                    "max-length": 20,
                                    onClose: onAddBonusModalClose
                                })) : _createCommentVNode("", true)
                            ]),
                        _: 1
                    }, 8, [
                        "selector"
                    ]),
                    _createVNode(ModalPortal, {
                        selector: _unref(ModalSelector).DESKTOP_INNER_MODAL
                    }, {
                        default: _withCtx(()=>[
                                isCopyLinkModalShown.value ? (_openBlock(), _createBlock(CopyLinkModal, {
                                    key: 0,
                                    "bonus-code-item": bonusCodeItem.value,
                                    onClose: onCopyLinkModalClose
                                }, null, 8, [
                                    "bonus-code-item"
                                ])) : _createCommentVNode("", true)
                            ]),
                        _: 1
                    }, 8, [
                        "selector"
                    ])
                ], 64)) : (_openBlock(), _createBlock(_unref(VLoaderDelayed), {
                    key: 0,
                    margin: _unref(VLoaderMargin).MEDIUM
                }, null, 8, [
                    "margin"
                ]))
            ], 2)), [
                [
                    _directive_auto_id,
                    'AffiliateBonusCodesRoutePage'
                ]
            ]);
        };
    }
});
